<template>
    <div class="footprint">
        <!-- <div class="footItem">
            <div class="time">
                <span class="month">03.</span>
                <span class="day">19</span>
                <span class="num">1件宝贝
                    <span class="line"></span>
                </span>
            </div>
            <div class="list">
                <div class="item">
                    <div class="pic">
                        <img class="cover" src="../../assets/imgs/place52.png"/>
                    </div>
                    <div class="price">
                        <span>￥1205.76</span>
                        <span>￥1528</span>
                    </div>
                    <div class="name">网红款布艺三人沙发现代奶...</div>
                </div>
            </div>
        </div> -->
        
        <div class="footItem" v-for="(item,i) in browseList" :key="i">
            <div class="time">
                <span class="month">{{item.month}}.</span>
                <span class="day">{{item.day}}</span>
                <span class="num">{{item.list.length}}件宝贝
                    <span class="line"></span>
                </span>
            </div>
            <div class="list">
                <div class="item" v-for="(subItem,j) in item.list" :key="j">
                    <div class="pic">
                        <img class="cover" :src="subItem.goods.cover?subItem.goods.cover:(subItem.goods.mainImages?subItem.goods.mainImages.split(',')[0]:'')"/>
                    </div>
                    <div class="price">
                        <span>￥{{subItem.goods.ourShopPrice}}</span>
                        <!-- <span>￥1528</span> -->
                    </div>
                    <div class="name">{{subItem.goods.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getBrowseRecordList} from "@/axios/api"
export default {
    data(){
        return{
            browseList:[],
        }
    },
    methods: {
        gainBrowseRecord(){
            getBrowseRecordList().then(res=>{
                // console.log("浏览记录",res);
                if(res.code==200){
                    var dateArr=[];
                    res.data.forEach(item=>{
                        var date=item.createTime.split(' ')[0]
                        if(!dateArr.includes(date)){
                            dateArr.push(date)
                        }
                    })

                    var arr=[];
                    dateArr.forEach(item=>{
                        var d=item.split('-')
                        var obj={
                            year:d[0],
                            month:d[1],
                            day:d[2],
                            list:[]
                        }
                        res.data.forEach(browse=>{
                            if(browse.createTime.split(' ')[0]==item){
                                obj.list.push(browse)
                            }
                        })
                        arr.push(obj)
                        // arr.push(obj)
                    })
                    // console.log(dateArr)
                    // console.log(arr)

                    this.browseList=arr;
                }
            })
        }
    },
    created() {
        this.gainBrowseRecord();
    },
}
</script>

<style lang="less" scoped>
.footprint{
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    margin-bottom: 20px;
    .footItem{
        .time{
            display: flex;
            align-items: baseline;
            .month{
                font-size: 16px;
                font-weight: 500;
                color: rgba(56, 56, 56, 1);
            }
            .day{
                font-size: 30px;
                font-weight: 700;
                color: rgba(56, 56, 56, 1);
            }
            .num{
                font-size: 11px;
                font-weight: 500;
                color: rgba(56, 56, 56, 1);
                flex: 1;
                margin-left: 3px;
                display: flex;
                .line{
                    flex:1;
                    height: 1px;
                    background: rgba(166, 166, 166, 1);
                    align-self: center;
                }
            }
            
        }
        .list{
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            .item{
                width: 184px;
                background: rgba(255, 255, 255, 1);
                margin-right: 15px;
                margin-bottom: 15px;
                .pic{
                    width: 184px;
                    height: 184px;
                    .cover{
                        width: 100%;
                        height: 100%;
                    }
                }
                .price{
                    padding: 9px 7px;
                    &>span:nth-child(1){
                        font-size: 13px;
                        font-weight: 500;
                        color: rgba(235, 55, 36, 1);
                    }
                    &>span:nth-child(2){
                        font-size: 11px;
                        font-weight: 400;
                        color: rgba(176, 176, 176, 1);
                        margin-left: 6px;
                        text-decoration: line-through;
                    }
                }
                .name{
                    padding: 0 7px;
                    padding-bottom: 9px;
                    font-size: 13px;
                    font-weight: 400;
                    color: rgba(176, 176, 176, 1);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>